@use 'styles/abstracts' as *;

.coord {
  padding: 0.5rem;
  @include breakpoint(tab-land) {
    padding: 0.75rem 0.75rem 6.5rem;
  }
  .tabbarContainer {
    display: flex;
    flex-wrap: nowrap;
    border-radius: 0.5rem;
    border: 1px solid $col-primary-600;
    height: 2.5rem;
    margin: 0.25rem 0 1.25rem 0;
    .addressBtn {
      border-radius: calc(0.5rem - 1px) 0 0 calc(0.5rem - 1px);
      background-color: $col-white;
      color: $col-gray-700;
      width: 100%;
    }
    .coordBtn {
      border-radius: 0 calc(0.5rem - 1px) calc(0.5rem - 1px) 0;
      background-color: $col-white;
      color: $col-gray-700;
      width: 100%;
    }
    .selected {
      background-color: $col-primary-600;
      color: $col-white;
    }
  }
  .wrap {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
    :global(.MuiFormControl-root) {
      flex: 1;
    }
    :global(.MuiInputBase-input) {
      height: 2.25rem;
      padding: 0 0.5rem;
    }
    :global(.MuiInputBase-input)[type='search'] {
      height: auto;
    }
    label {
      color: $col-primary-600;
    }
    span {
      color: $col-gray-500;
    }
    .button {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      background-color: $col-gray-100;
      pointer-events: none;
      svg {
        width: 1.5rem;
      }
      &.enabled {
        pointer-events: all;
        background-color: $col-primary-600;
      }
    }
    .closeIcon {
      cursor: pointer;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

