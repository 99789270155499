@use 'styles/abstracts' as *;

.container {
  border-radius: 1.25rem;
  border: 2px solid $col-gray-500;
  background-color: $col-white;
  position: fixed;
  bottom: 1.5rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  z-index: 10;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
  }
  .coords {
    color: $col-gray-700;
    font-size: 0.8125rem;
  }
}

