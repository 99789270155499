@use 'styles/abstracts' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  z-index: 1000;
  background-color: white;
  .spinner {
    width: 10%;
  }
}

