@use 'styles/abstracts' as *;

.anchor {
  position: absolute;
}

.arrow {
  position: absolute;
  font-size: 0.75rem;
  width: 3em;
  height: 3em;
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
}

.paper {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $col-white;
  border-radius: 0.75rem;
  padding: 0.75rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
  gap: 0.25rem;
  .main {
    display: flex;
    gap: 0.25rem;
    .closeButton {
      height: 2rem;
      width: 2rem;
      padding: 0.5rem;
      display: flex;
      svg {
        height: 1rem;
        width: 1rem;
        path {
          stroke: $col-gray-900;
        }
      }
      &:hover {
        background-color: $col-gray-100;
      }
      &:active {
        background-color: $col-gray-300;
      }
    }
    .body {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      .small {
        font-size: 0.8125rem;
      }
      .title {
        color: $col-gray-700;
      }
      .props {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        .key {
          color: $col-gray-600;
        }
        .value {
          color: $col-gray-900;
        }
      }
    }
  }
}

.popper {
  z-index: 1;
  .buttons {
    user-select: none;
    display: flex;
    width: 100%;
    height: 2.5em;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin-top: 0.75rem;
    gap: 0.5em;
    color: $col-gray-800;
    border: 1px solid $col-gray-300;
    svg {
      fill: transparent;
    }
    &:hover {
      background-color: $col-gray-100;
      border: 1px solid $col-gray-500;
    }
    &:active {
      background-color: $col-gray-300;
    }
  }
  & > div {
    position: relative;
  }
  &[data-popper-placement*='bottom'] {
    & > div {
      margin-top: 0.75rem;
    }
    & .arrow {
      top: 0;
      left: 0;
      margin-top: -0.9em;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 0 1em 1em 1em;
        border-color: transparent transparent $col-white transparent;
      }
    }
  }
  &[data-popper-placement*='top'] {
    & > div {
      margin-bottom: 0.75rem;
    }
    & .arrow {
      bottom: 0;
      left: 0;
      margin-top: 2px;
      margin-bottom: -0.9em;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 1em 1em 0 1em;
        border-color: $col-white transparent transparent transparent;
      }
    }
  }
  &[data-popper-placement*='right'] {
    & > div {
      margin-left: 0.75rem;
    }
    & .arrow {
      left: 0;
      margin-top: 2px;
      margin-left: -0.9em;
      height: 3em;
      width: 1em;
      &::before {
        border-width: 1em 1em 1em 0;
        border-color: transparent $col-white transparent transparent;
      }
    }
  }
  &[data-popper-placement*='left'] {
    & > div {
      margin-right: 0.75rem;
    }
    & .arrow {
      right: 0;
      margin-top: 2px;
      margin-right: -0.9em;
      height: 3em;
      width: 1em;
      &::before {
        border-width: 1em 0 1em 1em;
        border-color: transparent transparent transparent $col-white;
      }
    }
  }
}
