@use 'styles/abstracts' as *;

.round {
  border-radius: 0.75rem;
}

.header {
  min-height: 2.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1rem;
  align-items: center;
  justify-content: space-between;
  .iconText {
    display: grid;
    grid-template-columns: 1.625rem 1fr;
    column-gap: 0.5rem;
    color: $col-primary-600;
    align-items: center;
    justify-content: center;
    svg {
      height: 1.375rem;
    }
  }
}

